import React from 'react';
import {AmplifyAuthenticator, AmplifySignUp} from '@aws-amplify/ui-react';
import {AuthState, onAuthUIStateChange} from '@aws-amplify/ui-components';
import {Box, Container} from "@material-ui/core";
import Home from "src/components/Home";
import ApiService from 'src/service/api_service';

export interface AuthStateAppProps {
    /**
     * Reference to the API Client used by the application
     */
    api: ApiService;
}

const AuthStateApp: React.FunctionComponent<AuthStateAppProps> = (props: AuthStateAppProps) => {
    const [authState, setAuthState] = React.useState<AuthState>();
    const [user, setUser] = React.useState<object | undefined>();

    React.useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState);
            setUser(authData)
        });
    }, []);

    return authState === AuthState.SignedIn && user ? (
        <>
            <Box >
                <Home
                    api={props.api}/>
            </Box>
        </>
    ) : (
        <Container>
            <Box >
                <AmplifyAuthenticator usernameAlias="email" >
                    <AmplifySignUp
                        slot="sign-up"
                        usernameAlias="email"
                        formFields={[
                            { type: "email" },
                            { type: "password" }
                        ]}
                    />
                </AmplifyAuthenticator>
            </Box>
        </Container>
    );
}

export default AuthStateApp;