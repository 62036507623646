import {HOST, STAGE_URL} from "src/config/endpoints";

export const getAmplifyConfig = (host: string) => {
    const devAlias = `${process.env.DEV_ALIAS || 'dev'}`;
    const amplifyConfig = new Map();
    
    // change value of local dev cognito config based on the individual dev stack for testing your website locally.
    amplifyConfig.set(HOST.LOCAL_DEV, {
        identityPoolId: 'us-east-1:8e7ae695-2c8d-4dce-8fda-927008443858',
        region: 'us-east-1',
        userPoolId: 'us-east-1_Ay0BbiXKG',
        userPoolWebClientId: '3n4f4i8knuss4u0g7o4cudpjv3',
        name: 'api',
        endpoint: STAGE_URL.DEV,
        surveyTemplateBucketName: `${devAlias}-alpha-survey-id-bucket`,
        tempAudioBucketName: `${devAlias}-alpha-temp-audio-response-bucket`,
    });

    // change value of dev cognito config based on the individual dev stack for testing your website on your aws account
    amplifyConfig.set(HOST.DEV, {
        identityPoolId:'us-east-1:6344d473-e14a-4cbb-a828-3b81842191cc',
        region: 'us-east-1',
        userPoolId: 'us-east-1_s0T0Zb8Fh',
        userPoolWebClientId: '6gc6nmmv7hh3nj06tge98ras60',
        name: 'api',
        endpoint: STAGE_URL.DEV,
        // this bucket name will need to be updated with your dev alias
        surveyTemplateBucketName: `alpha-survey-id-bucket`,
        tempAudioBucketName: `alpha-temp-audio-response-bucket`,
    });

    amplifyConfig.set(HOST.BETA, {
        identityPoolId: "us-east-1:bf8a7ff2-f8ec-4bda-b468-520239bf570d",
        region: "us-east-1",
        userPoolId: "us-east-1_rFJ3I1WFs",
        userPoolWebClientId: "74j4mq68lpffn98ou8v03o4teu",
        name: 'api',
        endpoint: STAGE_URL.BETA,
        surveyTemplateBucketName: `beta-survey-id-bucket`,
        tempAudioBucketName: `beta-temp-audio-response-bucket`,
    });

    // TODO: To be configured when prod is configured.
    amplifyConfig.set(HOST.PROD, {
        identityPoolId: "us-east-1:9dedb370-ca5a-4b07-92b1-687ac2379e60",
        region: "us-east-1",
        userPoolId: "us-east-1_EAdf6aY91",
        userPoolWebClientId: "3jn9lphgvm18poq9itt3ruf6e4",
        name: 'api',
        endpoint: STAGE_URL.PROD,
        surveyTemplateBucketName: `prod-survey-id-bucket`,
        tempAudioBucketName: `prod-temp-audio-response-bucket`,
    });

    return amplifyConfig.get(host)
}
