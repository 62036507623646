export const AUDIO_MODULE_QUESTION_NAME: string = "audio_module";

/**
 * JSON to be passed to Survey.ComponentCollection.Instance.add in order to add a new question type.
 * Adds a question type that asks an audio recording question. This must be used alongside a widget
 * that injects the actual audio recording component into the question
 */
const AudioRecordingQuestion: any = {
  name: "audiorecording",
  questionJSON: {
    type: "text",
    name: AUDIO_MODULE_QUESTION_NAME,
    placeHolder: "audio_recording",
  },
};

export default AudioRecordingQuestion;
