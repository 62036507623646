import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {Button, Link} from "@material-ui/core";
import {Auth, Hub} from 'aws-amplify';
import {useHistory} from "react-router-dom";

const styles = {
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
};

const ApplicationBar = (props:any) =>{
    const { classes } = props;
    const history = useHistory();
    const handleSignOutButtonClick = async () => {
        try {
            await Auth.signOut();
            Hub.dispatch('UI Auth', {   // channel must be 'UI Auth'
                event: 'AuthStateChange',    // event must be 'AuthStateChange'
                message: 'signedout'    // message must be 'signedout'
            });
            history.push("/");
        } catch (error) {
            console.log('error signing out: ', error);
        }
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton className={classes.menuButton} color="inherit" aria-label="Menu">
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" color="inherit" className={classes.grow}>
                        <Link href="/" color="inherit" underline="none">Data Collection</Link>
                    </Typography>
                    <Button color="inherit" onClick={handleSignOutButtonClick}>Sign Out</Button>
                </Toolbar>
            </AppBar>
        </div>
    );
}


export default withStyles(styles)(ApplicationBar);