/**
 * Enumerates the question types in survey js (both custom and default)
 * that we support in our backend for storing in datamart. If a question
 * is not one of these types that questions data will not be stored. In order
 * to add a new question type to this list. Add it here and implement the
 * appropriate functions below as needed.
 */
export enum SupportedSurveyJSQuestionTypes {
  CHECKBOX = "checkbox",
  DROPDOWN = "dropdown",
  TEXT = "text",
  RADIOGROUP = "radiogroup",
  COMMENT = "comment",
  MATRIX = "matrix",
  AUDIORECORDING = "audiorecording",
  CONSENTFORM = "consentform",
}

/**
 * Enumerates the different objects we can map to in our backend.
 */
export enum BackendResponseVariant {
  MULTI_SELECTION_RESPONSE = "MULTI_SELECTION_RESPONSE",
  SINGLE_SELECTION_RESPONSE = "SINGLE_SELECTION_RESPONSE",
  MATRIX_RESPONSE = "MATRIX_RESPONSE",
  AUDIO_RESPONSE = "AUDIO_RESPONSE",
}

export const UNSUPPORTED_SURVEYJS_QUESTION_TYPE_ERROR_MESSAGE: string = "Failed to save question: question type not supported";

export interface FormattedSurveyQuestionResult {
  id: string;
  questionType: string;
  responseVariant: BackendResponseVariant;
  questionText: string;
  userResponse: any;
}

export interface SingleAnswerTextSurveyQuestionResult
  extends FormattedSurveyQuestionResult {
  userResponse: string;
}

export interface MultiAnswerTextSurveyQuestionResult
  extends FormattedSurveyQuestionResult {
  userResponse: string[];
}

export interface MatrixSingleResponseSurveyQuestionResult
  extends FormattedSurveyQuestionResult {
  userResponse: Record<string, string>;
}

export interface AudioSingleResponseSurveyQuestionResult
  extends FormattedSurveyQuestionResult {
  userResponse: {
    s3Url: string,
  };
}

/**
 * All question types share a base data set which is mapped in this function.
 * 
 * @param {BackendResponseVariant} responseVariant The response variant we want to represent this response as on our backend
 * @param {any} question a single survey js question response 
 * @returns {Partial<FormattedSurveyQuestionResult>} The base data that all questions will require from a surveyJS question
 */
function initializeCommonQuestionFields(responseVariant: BackendResponseVariant, question: any): Partial<FormattedSurveyQuestionResult> {
  return {
    id: question.name,
    questionType: question.getType(),
    responseVariant,
    questionText: question.title,
  }
}

/**
 * Constructs a backend question based on a surveyJS question. This surveyJS question is able to be represented by a single text based response.
 * i.e. a response to a dropdown, or radio button, or audio s3 location, etc.
 * 
 * @param {BackendResponseVariant} responseVariant The response variant we want to represent this response as on our backend
 * @param {any} question a single survey js question response 
 * @returns {SingleAnswerTextSurveyQuestionResult}
 */
function createSingleAnswerTextSurveyQuestionResult(
  responseVariant: BackendResponseVariant,
  question: any
): SingleAnswerTextSurveyQuestionResult {
    return {
      ...initializeCommonQuestionFields(responseVariant, question) as FormattedSurveyQuestionResult,
      userResponse: question.value as string,
    };
}

/**
 * Constructs a backend question based on a surveyJS question. This surveyJS question is able to be represented by an array of text based responses.
 * i.e. a response to a multiselect checkbox
 * 
 * @param {BackendResponseVariant} responseVariant The response variant we want to represent this response as on our backend
 * @param {any} question a single survey js question response 
 * @returns {MultiAnswerTextSurveyQuestionResult}
 */
function createMultiAnswerTextSurveyQuestionResult(
  responseVariant: BackendResponseVariant,
  question: any,
): MultiAnswerTextSurveyQuestionResult {
    return {
      ...initializeCommonQuestionFields(responseVariant, question) as FormattedSurveyQuestionResult,
      userResponse: question.value as string[],
    };
}

/**
 * Constructs a backend question based on a surveyJS question. This surveyJS question is able to be represented by an matrix of text based responses.
 * i.e. a response to a list of single answer text based responses.
 * 
 * @param {BackendResponseVariant} responseVariant The response variant we want to represent this response as on our backend
 * @param {any} question a single survey js question response 
 * @returns {MatrixSingleResponseSurveyQuestionResult}
 */
function createMatrixSingleResponseSurveyQuestionResult(
  responseVariant: BackendResponseVariant,
  question: any,
): MatrixSingleResponseSurveyQuestionResult {
    return {
      ...initializeCommonQuestionFields(responseVariant, question) as FormattedSurveyQuestionResult,
      userResponse: question.propertyHash.value,
    };
}

function createAudioSingleResponseSurveyQuestionResult(
  responseVariant: BackendResponseVariant,
  question: any,
): AudioSingleResponseSurveyQuestionResult {
  return {
    ...initializeCommonQuestionFields(responseVariant, question) as FormattedSurveyQuestionResult,
    userResponse: {
      s3Url: question.value
    },
  }
}

/**
 * Constructs a backend question based on a surveyJS question. Needed as composite custom questions like consent form, return a json object instead of a single answer
 * 
 * @param {BackendResponseVariant} responseVariant The response variant we want to represent this response as on our backend
 * @param {any} question a single survey js question response 
 * @returns {SingleAnswerTextSurveyQuestionResult}
 */
 function createConsentFormSingleResponseSurveyQuestionResult(
  responseVariant: BackendResponseVariant,
  question: any,
): SingleAnswerTextSurveyQuestionResult {
    return {
      ...initializeCommonQuestionFields(responseVariant, question) as FormattedSurveyQuestionResult,
      userResponse: question.propertyHash.value['consent-decision'][0],
    };
}

type QuestionFactoryFunction = (responseVariant: BackendResponseVariant, question: any) => FormattedSurveyQuestionResult;

type FactoryFunctionResponseVariantPair = {
  factoryFunction: QuestionFactoryFunction;
  responseVariant: BackendResponseVariant;
}

/**
 * We use this map to pair survey js question types with the factory functions for our
 * own backend response format factory functions. If we choose to support new questions
 * they should be added to {SupportedSurveyJSQuestionTypes} and then an existing or new 
 * factory function should be referenced here.
 */
export const questionTypeToFactoryFunction: Record<
  SupportedSurveyJSQuestionTypes,
  FactoryFunctionResponseVariantPair
> = {
  [SupportedSurveyJSQuestionTypes.CHECKBOX]: {
      factoryFunction: createMultiAnswerTextSurveyQuestionResult,
      responseVariant: BackendResponseVariant.MULTI_SELECTION_RESPONSE,
    },
  [SupportedSurveyJSQuestionTypes.DROPDOWN]: {
      factoryFunction: createSingleAnswerTextSurveyQuestionResult,
      responseVariant: BackendResponseVariant.SINGLE_SELECTION_RESPONSE
    },
  [SupportedSurveyJSQuestionTypes.TEXT]: {
      factoryFunction: createSingleAnswerTextSurveyQuestionResult,
      responseVariant: BackendResponseVariant.SINGLE_SELECTION_RESPONSE
    },
  [SupportedSurveyJSQuestionTypes.RADIOGROUP]: {
      factoryFunction: createSingleAnswerTextSurveyQuestionResult,
      responseVariant: BackendResponseVariant.SINGLE_SELECTION_RESPONSE
    },
  [SupportedSurveyJSQuestionTypes.COMMENT]: {
      factoryFunction: createSingleAnswerTextSurveyQuestionResult,
      responseVariant: BackendResponseVariant.SINGLE_SELECTION_RESPONSE
    },
  [SupportedSurveyJSQuestionTypes.MATRIX]: {
      factoryFunction: createMatrixSingleResponseSurveyQuestionResult,
      responseVariant: BackendResponseVariant.MATRIX_RESPONSE
    },
  [SupportedSurveyJSQuestionTypes.AUDIORECORDING]: {
      factoryFunction: createAudioSingleResponseSurveyQuestionResult,
      responseVariant: BackendResponseVariant.AUDIO_RESPONSE
    },
  [SupportedSurveyJSQuestionTypes.CONSENTFORM]: {
    factoryFunction: createConsentFormSingleResponseSurveyQuestionResult,
    responseVariant: BackendResponseVariant.SINGLE_SELECTION_RESPONSE
  }
};

/**
 * Takes surveyJS response data and formats it to contain only the data we care about.
 * 
 * @param {any} survey The response data we want to convert 
 * @returns {FormattedSurveyQuestionResult[]} Array of formatted response data
 */
export default function formatSurveyResults(
  survey: any
): FormattedSurveyQuestionResult[] {
  const resultData: FormattedSurveyQuestionResult[] = [];
  for (var key in survey.data) {
    const question = survey.getQuestionByValueName(key);
    if (question) {
      if (
        Object.values(SupportedSurveyJSQuestionTypes).includes(
          question.getType()
        )
      ) {
        const factoryPair: FactoryFunctionResponseVariantPair = questionTypeToFactoryFunction[
          question.getType() as SupportedSurveyJSQuestionTypes
        ];
        resultData.push(
          factoryPair.factoryFunction(factoryPair.responseVariant, question)
        );
      } else {
        console.log(UNSUPPORTED_SURVEYJS_QUESTION_TYPE_ERROR_MESSAGE);
        throw new Error(UNSUPPORTED_SURVEYJS_QUESTION_TYPE_ERROR_MESSAGE);
      }
    }
  }
  return resultData;
}
