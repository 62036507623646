import React from "react";
import {Box, Button, Container, TextField, Typography} from "@material-ui/core";
import ApiService from "src/service/api_service";
import {toast} from "react-hot-toast";
import Alert from "react-bootstrap/Alert";
import "survey-react/modern.css";
import "survey-react/survey.css";
import {Redirect} from 'react-router-dom'

export interface SurveyCodePromptProps {
    /**
     * Reference to the API Client used by the application
     */
    api: ApiService;
}

interface State  {
    user: string;
    loading:boolean;
    surveyCode:string
    surveyId:string,
    error:boolean,
    helperText:string,
    objectVersion: string
}
export default class SurveyCodePrompt extends React.Component<SurveyCodePromptProps,State>{
    state:State = {
        user: "",
        loading:false,
        surveyCode:"",
        surveyId:"",
        error: false,
        helperText:"",
        objectVersion: ""
    }

    async validateSurvey() {
        return await this.props.api.validateAndGetSurveyIdFromSurveyCode(this.state.surveyCode)
            .then(response => {
                toast.dismiss()
                this.setState({
                    surveyId:response.surveyId,
                    objectVersion: response.objectVersion,
                    surveyCode: response.surveyCode,
                    error:false
                })
            })
    }

    _handleOnClick = ()=>{
        if(!this.state.surveyCode) {
            this.setState({error:true,helperText:"Input Required"})
            return
        }
        this.setState({loading:true,error:false})
        toast.promise(this.validateSurvey(), {
            loading: "Checking survey code",
            success: "Validated survey code",
            error: (err)=>(<span>
                       <div>
                           <Alert variant={'danger'}>
                                {err.toString()}
                            </Alert>
                        </div>
                    </span>)
        }).catch(reason => {
            this.setState({error:true,helperText: reason})
        }).finally(()=>this.setState({loading:false}))
    }

    _handleTextFieldChange = (e:any) => {
        this.setState({
            surveyCode: e.target.value
        });
    }

    render() {

        if (this.state.surveyId && !this.state.loading) {
            return <Redirect to={'/survey/'+ this.state.surveyId + "/" +
                this.state.objectVersion + "/" +  this.state.surveyCode}/>;
        }
        return <>
            <Container >
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="50vh"

                >
                    <Container maxWidth={"sm"} >
                        <Box pt={2}>
                        <Typography>
                            Please enter the survey code shared with you to access the survey.<br/>
                        </Typography>
                        </Box>
                        <Box pt={2}>
                        <Typography >
                            Once validated, you will be redirected to the survey
                        </Typography>
                        </Box>
                        <Box pt={4}>
                        <TextField
                            label="Survey Code"
                            variant="outlined"
                            value={this.state.surveyCode}
                            autoFocus
                            fullWidth
                            required
                            error = {this.state.error}
                            helperText= {this.state.error ? this.state.helperText: ""}
                            onChange={this._handleTextFieldChange} />
                        </Box>
                        <Box pt={2}>
                            <Button
                                variant="contained"
                                disabled={this.state.loading}
                                onClick={this._handleOnClick}
                            >
                                Validate
                            </Button>
                        </Box>
                    </Container>

                </Box>
            </Container>
        </>
    }
}

