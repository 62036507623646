import React from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import SurveyAppFunction from "src/components/SurveyApp";
import {Box} from "@material-ui/core";
import ApplicationBar from "src/components/ApplicationBar";
import {Toaster} from "react-hot-toast";
import SurveyCodePrompt from "src/components/SurveyCodePrompt";
import ApiService from 'src/service/api_service';

export interface HomeScreenProps {
    /**
     * Reference to the API Client used by the application
     */
    api: ApiService;
}

const Home: React.FunctionComponent<HomeScreenProps> = (props: HomeScreenProps) => {
    return (
        <>
            <Box >
                <BrowserRouter>
                    <ApplicationBar/>
                    <Toaster position="top-center"/>
                    <Switch>
                        <Route path ="/survey/:surveyId/:objectVersion/:surveyCode">
                            <SurveyAppFunction
                                api={props.api}/>
                        </Route>
                        <Route path ="/" exact>
                            <SurveyCodePrompt
                                api={props.api}/>
                        </Route>
                    </Switch>
                </BrowserRouter>
            </Box>
        </>
    );
}

export default Home;
