import AuthUtils from "src/utils/AuthUtils";
import {v4 as uuidv4} from "uuid";
import { APIClass, StorageClass } from "aws-amplify";

export default class ApiService {

    private amplifyAPI: APIClass;
    private amplifyStorage: StorageClass;
    private surveyTemplateBucketName: string;
    private tempAudioBucketName: string;

    public static GET_SURVEY_ERROR_MESSAGE: string = "Unable to get survey. Please retry, if the problem persists please contact your refering organization";

    constructor(
        amplifyAPI: APIClass,
        amplifyStorage: StorageClass,
        surveyTemplateBucketName: string,
        tempAudioBucketName: string,) {
        this.amplifyAPI = amplifyAPI;
        this.amplifyStorage = amplifyStorage;
        this.surveyTemplateBucketName = surveyTemplateBucketName;
        this.tempAudioBucketName = tempAudioBucketName;
    }

    async refreshSessionToken() {
        AuthUtils.refreshUserSession().then(() => {
            console.log("Refreshed user token")
        })
    }

    /**
     * Validates & Authenticates a user to a survey code
     * @param surveyId mapped to the the survey code
     */
    async validateAndGetSurveyIdFromSurveyCode(surveyCode: string) {
        let errorMessage:string;
        await this.refreshSessionToken()
        const init = {
            headers: {'Content-Type': 'application/json'},
            body: {surveyCode: surveyCode}
        }
        return this.amplifyAPI.post('api', '/validateSurveyToken', init)
            .then(response => {
                if (response.surveyCode === surveyCode) {
                    return {
                        surveyCode: response.surveyCode,
                        surveyId: response.surveyId,
                        objectVersion: response.objectVersion
                    }
                }
                return Promise.reject({errorMessage: 'Error validating the survey code'})
            }).catch((error) => {
                return this.handleError(error, errorMessage);
            })
    }

    /**
     * Handle error messages for survey code validation
     */
    private handleError(error: Record<any, any>, errorMessage: string) {
        if (error.errorMessage) {
            errorMessage = error.errorMessage
        } else if (error.response && error.response.data && error.response.data.errorMessage) {
            errorMessage = error.response.data.errorMessage
        } else {
            errorMessage = "Error validating the survey code"
        }
        console.log(errorMessage)
        return Promise.reject(errorMessage)
    }

    /**
     * Actual get request to fetch survey contents.
     * @param surveyId
     */
    // TODO: remove this an refactor in place of returning survey json from backend lambda: https://issues.labcollab.net/browse/DC-97
    async getSurvey(surveyId:string) {
        await this.refreshSessionToken()
        try {
            const result: any = await this.amplifyStorage.get(
                `${surveyId}.json`,
                {
                    bucket: this.surveyTemplateBucketName,
                    download: true,
                    cacheControl: 'no-cache',
                    region: 'us-east-1',
                })
            return await result.Body.text();
        } catch (e) {
            return ApiService.GET_SURVEY_ERROR_MESSAGE;
        }
    }

    /**
     * Used when the browser wants to replay the audio if lost from cache,
     * @param s3_key
     * @return A presigned s3 audio url ONLY
     */
    async getAudioPresignedS3url(s3_key:string) {
        await this.refreshSessionToken()
        return await this.amplifyStorage.get(
            s3_key,
            {
                bucket: this.tempAudioBucketName,
                level: 'private',
                region: 'us-east-1',
            })
    }

    /**
     * Put request to upload audio file to s3. User has permission to only upload audio for surveyId/sessionId/<file>
     * @param sessionId: unique sessionId
     * @param content: audio blob
     */
    async putAudioFileToS3(surveyId: string, sessionId:string, content:any) {
        await this.refreshSessionToken()
        const randomFileName = uuidv4() + ".wav"
        const s3Path = `${surveyId}/${sessionId}/${randomFileName}`;
        return this.amplifyStorage.put(
            s3Path,
            content,
            {
                bucket: this.tempAudioBucketName,
                level: "private",
                region: 'us-east-1',
            })
            .then((result: any) => {
                return result.key
            })
            .catch((error: any)=> {
                console.log(error)
                return Promise.reject(error)
            })
    }

    /**
     * Final call on user's 'Submit' to save response
     * @param userId
     * @param sessionId
     * @param responseJson
     */
    async submitSurveyResponse(
        objectVersion: string,
        surveyId: string,
        surveyCode: string,
        userId: string,
        sessionId: string,
        responseJson: {}
    ) {
        await this.refreshSessionToken()
        const body = {
            objectVersion: objectVersion,
            surveyId: surveyId,
            surveyCode: surveyCode,
            userId: userId,
            surveySessionId: sessionId,
            surveyResponse: responseJson
        }

        console.log("submitting survey response ")
        const request = {
            headers: {
                'Content-Type': 'application/json'
            },
            body: body
        };
        const response: Record<any, any> =  await this.amplifyAPI.post('api', '/submitSurvey', request)
            .catch(error => {
                console.log(error)
                return Promise.reject('Failed to submit response.')
            })

        if(response.confirmationCode !== null && response.confirmationCode !== undefined) {
            return response.confirmationCode
        } else{
            return Promise.reject('Failed to submit response.')
        }
    }

}
