import React from 'react';
import AudioRecorder2 from "src/components/AudioRecorder2";
import {v4 as uuidv4} from 'uuid';
import * as Survey from "survey-react";
import "survey-react/survey.css";
import "survey-react/modern.css";
import {
    Box,
    Button,
    Card,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography
} from "@material-ui/core";
import ApiService from "src/service/api_service";
import {toast} from 'react-hot-toast';
import {Link, useParams} from "react-router-dom";
import Alert from 'react-bootstrap/esm/Alert';
import {CardContent} from "@material-ui/core/index";
import {exit_submission_json, quit_survey_json} from "src/utils/constants";
import formatSurveyResults from 'src/utils/formatSurveyResults';

export interface SurveyAppProps {
    /**
     * Reference to the API Client used by the application
     */
    api: ApiService;
    /**
     * The id of the survey we are currently running
     */
    surveyId: string;
    /**
     * version of the survey pulled from the backend
     */
    objectVersion: string;
    /**
     * Code associated with the session
     */
    surveyCode: string;
}

interface State  {
    user: string;
    sessionId: string;
    surveyJson: any,
    surveyResponse:any,
    loading: boolean,
    surveyValidationCode:string,
    quitSurvey: boolean;
}
class SurveyApp extends React.Component<SurveyAppProps,State>{

    state:State = {
        sessionId: uuidv4(),
        user:"",
        surveyJson: null,
        surveyResponse: null,
        loading: false,
        surveyValidationCode:"",
        quitSurvey: false
    }

    constructor(props:SurveyAppProps) {
        super(props);
        // Adding a custom widget for Audio Recording, response is a string for s3 key
        Survey.CustomWidgetCollection.Instance.addCustomWidget({
            name: 'audio_recording',
            isFit: (question:any) => question.placeHolder == 'audio_recording',
            render: (question:any) => (
                <AudioRecorder2
                    surveyId={this.props.surveyId}
                    sessionId={this.state.sessionId}
                    previousRecordingS3Path={question.value}
                    api={this.props.api}
                    onRecordingComplete = {(s3_url:string) => { question.value = s3_url;}}
                />),
        });

        // Survey JS standard theme
        Survey.StylesManager
            .applyTheme("modern");
        Survey.Survey.cssType = "modern";

    }

    componentDidMount() {
        if(!this.state.surveyJson){
            this.setState({loading:true})
            this.props.api.getSurvey(this.props.surveyId)
                .then(json=> {
                    this.setState({surveyJson: json,loading:false })
                })
                .catch(reason => {
                    toast.error(reason)
                    this.setState({loading:false })
                })
        }
    }

    handleUnload(e: any) {
        const message = "\o/";

        (e || window.event).returnValue = message;
        return message;
    }

  handleMainSurveySubmit = (survey: any, options: any) => {
        let formattedSurvey = formatSurveyResults(survey)
        this.setState({ surveyResponse: formattedSurvey });
        if(formattedSurvey[0].id === "consent" &&
            formattedSurvey[0].userResponse === "No") {
            console.log("Survey is not taken")
            this.setState({quitSurvey: true})
        }
  };


  submitSurvey = (surveyData: any) => {
    toast
      .promise(
        this.props.api.submitSurveyResponse(
            this.props.objectVersion,
            this.props.surveyId,
            this.props.surveyCode,
            this.state.user,
            this.state.sessionId,
            surveyData
        ),
        {
          loading: "Submitting your responses",
          success: "Your response has been captured 👌.",
                error: <span>
                           <div>
                               <Alert variant={'danger'}>
                                    Error submitting your responses, please try again
                                </Alert>
                              <button onClick={() => {
                                  toast.dismiss();
                                  this.submitSurvey(surveyData)
                              }}>Retry</button>
                            </div>
                        </span>
            },
        ).then(response => {
            //survey completed remove user prompt when browser close
            window.removeEventListener('beforeunload', this.handleUnload);
            this.setState({surveyValidationCode:response})
        })
    }

    onAfterRenderQuestion = (sender:any,options:any)=> {
        // the buttons are pre-created in the survey json for consent survey
        if(options.question.name!="custom button container") {
            return;
        }
        const proceedToSubmitButton = document.getElementById("ntisurveysubmit");
        const deleteSubmissionButton = document.getElementById("ntisurveydelete");
        if(proceedToSubmitButton) {
            proceedToSubmitButton.addEventListener('click',()=>{
                proceedToSubmitButton?.setAttribute('disabled', 'disabled' )
                deleteSubmissionButton?.setAttribute('disabled', 'disabled' )
                this.submitSurvey(this.state.surveyResponse);
            })
        }

        if(deleteSubmissionButton) {
            deleteSubmissionButton.addEventListener('click',()=>sender.nextPage())
        }
    }

    redirectToHomePage = () => {
        window.location.href = "/";
    };

render() {
        const myCss = {
            page: {
                title: "page_title"
            }
        };
        /** Error Block when not authenticated **/
        const errorBlock = <> {
            !this.state.loading &&
            <Card elevation={0}>
                <CardHeader
                    title= {<Typography color="error" variant={"h4"}>❗Access Denied</Typography>}
                />
                <CardContent>
                    <Typography variant="body1" color="error">
                        No survey to display
                    </Typography>
                    <Link to="/" >
                        Back to homepage
                    </Link>
                </CardContent>
            </Card> }</>

         const verificationCodeModal =
                <> {<Dialog
                    open={this.state.surveyValidationCode!=''}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableBackdropClick={true}
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Verification Code"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Please save the completion verification token and provide it to the study manager.
                            After saving the token, click “Exit Study”.
                            <p>
                            <Typography variant={"h3"}>{this.state.surveyValidationCode}</Typography>
                            </p>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="inherit" onClick={()=>this.redirectToHomePage()}>Exit Study</Button>
                    </DialogActions>
                </Dialog> }
                </>

        return (
            <>
                <>
                    {verificationCodeModal}
                    <Box>
                        {/*Part 1, Actual survey content: Displayed when accepted T&C & visible*/}
                        {this.state.surveyJson == null ? errorBlock :
                            this.state.surveyResponse == null &&
                            <Survey.Survey
                                json = {this.state.surveyJson}
                                css = {myCss}
                                onComplete = {this.handleMainSurveySubmit}/>
                        }
                        {/* Part 2, quit survey when clicked on disagree */}
                        {this.state.surveyResponse != null &&
                            this.state.quitSurvey === true && <Survey.Survey
                            model = {new Survey.Model(quit_survey_json)}
                            css={myCss}
                        />}
                        {/*Part 3, Survey Completion*/}
                        {this.state.surveyResponse != null && this.state.quitSurvey === false
                            && <Survey.Survey
                            model = {new Survey.Model(exit_submission_json)}
                            css={myCss}
                            onAfterRenderQuestion ={this.onAfterRenderQuestion} />}
                    </Box>
                </>
            </>
        );
    }
}

/**
 * A function wrapper required to make it work with router. Just gets url parameters and passes to the component
 * @constructor
 */
function SurveyAppFunction(props: { api: ApiService}) {
    const {surveyId, objectVersion, surveyCode}:
        {surveyId:string, objectVersion:string, surveyCode: string} = useParams();
    return (
        <div>
            <SurveyApp
                api={props.api}
                surveyId={surveyId}
                objectVersion = {objectVersion}
                surveyCode = {surveyCode} />
        </div>
    );
}
export default SurveyAppFunction;
