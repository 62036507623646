import * as Survey from "survey-react";

export const CONSENT_FORM_QUESTION_NAME: string = "consentform";
const CONSENT_CONTENT_QUESTION_NAME: string = 'consentContent';
const CONSENT_INFORMATION_PROPERTY_NAME: string = "consentInformation";

/**
 * JSON to be passed to Survey.ComponentCollection.Instance.add in order to add a new question type.
 * Adds a question type that provides a template for a consent form, I agree must be selected, or the
 * user will not be able to proceed and an appropriate message will be shown
 * 
 * Example usage:
 * {
 *     "type": "consentform",
 *     "name": "Terms and Conditions",
 *     "consentInformation": "<your desired html with consent information>"
 * }
 */
 const ConsentFormQuestion: any = {
    name: CONSENT_FORM_QUESTION_NAME,
    elementsJSON: [
      {
        "type": "html",
        "name": CONSENT_CONTENT_QUESTION_NAME,
        "html": `placeholder`
      },
      {
          "type": "checkbox",
          "name": "consent-decision",
          "title": "Do you agree to the terms outlined above?",
          "hideNumber": true,
          "isRequired": true,
          "choices": [
              {
                  "value": "i-agree",
                  "text": "I agree"
              }
          ],
          "colCount": 0
      }
    ],

    onInit() {
      Survey.Serializer.addProperty(CONSENT_FORM_QUESTION_NAME, {
        name: CONSENT_INFORMATION_PROPERTY_NAME,
        type: 'string',
        default: 'empty',
        category: 'general'
      })
    },

    onLoaded(question: any) {
      this.changeConsentInformationHTML(question);
    },

    onPropertyChanged(question: any, propertyName: string, newValue: any) {
      if (propertyName === CONSENT_INFORMATION_PROPERTY_NAME) {
        this.changeConsentInformationHTML(question);
      }
    },

    changeConsentInformationHTML(question: any) {
      const consentContent: any = question.contentPanel.getQuestionByName(CONSENT_CONTENT_QUESTION_NAME);
      if (!!consentContent) {
        consentContent.html = question[CONSENT_INFORMATION_PROPERTY_NAME];
      }
    },
  };

  export default ConsentFormQuestion;