import * as React from "react";
import {CircularProgressbar,buildStyles} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {useEffect, useState} from "react";
import {Box} from "@material-ui/core";
import {Grid} from "@material-ui/core/index";

const CountdownTimer = (props:{ totalSeconds: number; })=> {
    const totalSeconds = props.totalSeconds
    const [elapsedSeconds, setElapsedSeconds] = useState(0)
    const [progress, setProgress] = useState(0)
    const roundProgress = (progress: number) => {
        const factor = Math.pow(10, 2)
        return Math.round(progress * factor) / factor
    }
    const handleTimer = () => {
        const newProgress = Math.max(roundProgress(
            ((elapsedSeconds) / totalSeconds) * 100
        ),progress)
        setProgress(newProgress)
        setElapsedSeconds(prevState => prevState+0.1)
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if(elapsedSeconds>totalSeconds) {
                clearInterval(interval)
            } else {
                handleTimer()
            }
        }, 100);
        return ()=>clearInterval(interval)
    });
    return (
        <Box>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '1vh' }}
            >
                <div style={{ width: 100, height: 100 }}>
                    <CircularProgressbar
                    value={progress}
                    strokeWidth={50}
                    styles={buildStyles({
                        strokeLinecap: "butt"})}/>
                </div>
            </Grid>
        </Box>)
}
export default CountdownTimer;