/**
 * JSON to be passed to Survey.ComponentCollection.Instance.add in order to add a new question type.
 * Adds a question type that provides information on how to complete an audio question.
 */
const RecordingInstructionsQuestion: any = {
  name: "recordinginstructions",
  questionJSON: {
    type: "html",
    name: "recording instructions",
    indent: 2,
    html: '<ul style="line-height:1.5em">\n<li>Before you start reading click on RECORD to start recording. \n<li>When you are done click on STOP RECORDING. If you wish to delete recording click on DELETE and RE-RECORD. \nThis deletes the previous recording and you can start/end a new recording by clicking on RECORD/STOP RECORDING.\n<li>After you are done click NEXT to proceed to the next task. \n</ul>\n<p/>\n',
  },
};

export default RecordingInstructionsQuestion;
