export const quit_survey_json = {
    "completedHtml": "<h2>Thank you. You may close this browser tab or window.</h2>",
    "pages": [
        {
            "name": "Quit confirmation",
            "elements": [
                {
                    "type": "html",
                    "name": "Quit Survey",
                    "html": "<h2>Are you sure, you want to quit the survey?</h2>" +
                        "<p>If this was a mistake, click on the title to go back to homepage.</p>"
                }
            ]
        }
    ],
    "completeText": "Quit Survey"
}

export const exit_submission_json = {
    "completedHtml": "<h2>Thank you. You may close this browser tab or window.</h2>",
    "pages": [
        {
            "name": "Submit response page",
            "elements": [
                {
                    "type": "html",
                    "name": "consent content",
                    "html": "<h3>  Thank you for your participation in the study. You might qualify to re-take the study after 7+ days. It that is the case, the study coordinator will contact you via e-mail.\n<p/>\nSelect PROCEED TO VERIFICATION to upload the data or DELETE SESSION to erase the data.\n<p/>\nThe data submission can take up to XX. Wait for the submission confirmation screen to appear before closing your browser.\n\n"
                },
                {
                    "type": "html",
                    "name": "custom button container",
                    "html": "<div class=\"sv-footer sv-body__footer sv-clearfix\" style=\"color: #7f7f7f \">\n" +
                        "<input id = \"ntisurveysubmit\" class=\"sv-btn sv-footer__prev-btn\"" +
                        " type=\"button\" value=\"PROCEED TO VERIFICATION TOKEN\" style=\"margin-right: 5px; color: #008800\">\n" +
                        "<input id =" +
                        " \"ntisurveydelete\"" +
                        " class=\"sv-btn sv-footer__complete-btn\" type=\"button\" value=\"DELETE SESSION\" style=\"margin-right: 5px; color: #ff0000\"></div>"
                }
            ],
            "navigationButtonsVisibility": "hide"
        },
        {
            "name": "Quit confirmation",
            "elements": [
                {
                    "type": "html",
                    "name": "Are you sure you want to leave the study?",
                    "html": "You selected to delete the session. This action will erase all recorded data and you will not receive the payment. If you made selection by mistake go back to the previous screen, otherwise confirm you selection.\n"
                }
            ]
        }
    ],
    "pagePrevText": "Go Back",
    "completeText": "PROCEED TO DELETE DATA AND EXIT SESSION"
}
