import * as Survey from "survey-react";
import AudioRecordingQuestion from "../components/custom_questions/AudioRecordingQuestion";
import RecordingInstructionsQuestion from "../components/custom_questions/RecordingInstructionsQuestion";
import ConsentFormQuestion from "../components/custom_questions/ConsentFormQuestion";
import SurveyIntroductionContent from "../components/custom_questions/SurveyIntroduction";

export default function initializeSurveyConfiguration(): void {
  // Initialize custom question types here
  Survey.ComponentCollection.Instance.add(AudioRecordingQuestion);
  Survey.ComponentCollection.Instance.add(RecordingInstructionsQuestion);
  Survey.ComponentCollection.Instance.add(ConsentFormQuestion);
  Survey.ComponentCollection.Instance.add(SurveyIntroductionContent);
}
