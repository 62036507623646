// change value of DEV api id based on the individual dev stack.
const devApiId = "dhlxib0vc5";

export const STAGE_URL = {
    DEV: `https://${devApiId}.execute-api.us-east-1.amazonaws.com/alpha`,
    BETA: 'https://rrs5nvo2v7.execute-api.us-east-1.amazonaws.com/beta',
    PROD: '', //TODO: To be configured
}

export enum HOST {
    LOCAL_DEV = 'localhost:4321',
    DEV = 'some-cloudfront-url',
    BETA = 'beta.nti-data-collection.alexa.amazon.dev',
    PROD = 'nti-data-collection.alexa.amazon.dev'
}
