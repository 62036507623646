import { Auth } from "aws-amplify";

export default class AuthUtils {
  /**
   * Useful when user attributes are changed which triggers change in permissions of the user.
   * Aws amplify doesn't do it itself so manually refresh session and thus aws credentials
   */
  static async refreshUserSession() {
    return await Auth.currentAuthenticatedUser({ bypassCache: true }).then(
      (user) => {
        Auth.currentSession().then((session) => {
          user.refreshSession(
            session.getRefreshToken(),
            (err: any, sessionNew: any) => {
              if (err) {
                console.log("error refreshing user credentials err:", err);
              }
            }
          );
        });
      }
    );
  }

  /**
   * Get user Id(Cognito Identity Id us-east-1:random_uuid_string)
   */
  static async getCurrentUserId() {
    return await Auth.currentUserInfo().then((userInfo) => {
      return userInfo.id;
    });
  }
}
