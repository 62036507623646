import * as Survey from "survey-react";

export const SURVEY_INTRODUCTION_NAME: string = "surveyIntroduction";
const SURVEY_INTRODUCTION_CONTENT_NAME: string = 'surveyIntroductionContent';
const SURVEY_INFORMATION_PROPERTY_NAME: string = "surveyIntroductionInformation";
/**
 * JSON to be passed to Survey.ComponentCollection.Instance.add in order to add a new question type.
 * Adds a question type that provides a template for a survey Introduction content.
 *
 * Example usage:
 * {
 *     "type": "surveyIntroduction",
 *     "name": "Survey Introduction",
 *     "surveyIntroductionInformation": "<your desired html with survey introduction information>"
 * }
 */
const SurveyIntroductionContent: any = {
    name: SURVEY_INTRODUCTION_NAME,
    elementsJSON: [
        {
            "type": "html",
            "name": SURVEY_INTRODUCTION_CONTENT_NAME,
            "html": `placeholder`
        }
    ],

    onInit() {
        Survey.Serializer.addProperty(SURVEY_INTRODUCTION_NAME, {
            name: SURVEY_INFORMATION_PROPERTY_NAME,
            type: 'string',
            default: 'empty',
            category: 'general'
        })
    },

    onLoaded(question: any) {
        this.changeSurveyIntroductionInformationHTML(question);
    },

    onPropertyChanged(question: any, propertyName: string, newValue: any) {
        if (propertyName === SURVEY_INFORMATION_PROPERTY_NAME) {
            this.changeSurveyIntroductionInformationHTML(question);
        }
    },

    changeSurveyIntroductionInformationHTML(question: any) {
        const surveyIntroductionContent: any = question.contentPanel.getQuestionByName(SURVEY_INTRODUCTION_CONTENT_NAME);
        if (!!surveyIntroductionContent) {
            surveyIntroductionContent.html = question[SURVEY_INFORMATION_PROPERTY_NAME];
        }
    },
};

export default SurveyIntroductionContent;
