import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import AuthStateApp from "src/components/AuthStateApp";
import { createClient } from "src/config/createClient";
import initializeSurveyConfiguration from "./config/initializeSurveyConfiguration";
import ApiService from "./service/api_service";
import { API, Storage } from 'aws-amplify';
import Client from "./config/client";

const client: Client = createClient();
initializeSurveyConfiguration();

const api: ApiService = new ApiService(
  API,
  Storage,
  client.surveyTemplateBucketName,
  client.tempAudioBucketName);

ReactDOM.render(
  <React.StrictMode>
    <AuthStateApp
     api={api}/>
  </React.StrictMode>,
  document.getElementById("root")
);
