import React from 'react'

import AudioReactRecorder, {RecordState} from 'audio-react-recorder'
import AudioPlayer from 'material-ui-audio-player';
import Box from '@material-ui/core/Box';
import {Card, CardContent, Fab, Grid, LinearProgress, Paper, styled, Tooltip} from "@material-ui/core/index";
import {Delete, Mic, Stop} from "@material-ui/icons";
import ApiService from "src/service/api_service";
import CountdownTimer from "src/components/CoundownTimer";

interface AudioRecorder2Props {
    surveyId: string;
    sessionId: string;
    previousRecordingS3Path: string;
    api: ApiService;
    onRecordingComplete: (s3_url: string) => void;
}

interface State {
    recordState:any,
    audioS3Path:string
    audioData:any,
    isUploading:boolean
    error: any,
}

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    padding: theme.spacing(1)
}));

export default class AudioRecorder2 extends React.Component<AudioRecorder2Props, State> {

    state:State = {
        recordState: null,
        audioS3Path: "",
        audioData: null,
        isUploading:false,
        error: undefined,
    }

    constructor(props: any, api: ApiService) {
        super(props)
    }

    componentDidMount() {
        if(this.props.previousRecordingS3Path) {
            this.props.api.getAudioPresignedS3url(this.props.previousRecordingS3Path).then(url=> {
                this.setState({
                    recordState: RecordState.STOP,
                    audioData:  {url:url},
                    isUploading:false,
                    audioS3Path:this.props.previousRecordingS3Path
                })
            }).catch(error=>console.log(error))
        }
    }

    //called when start button is clicked on recorder
    start = () => {
        this.setState({
            recordState: RecordState.START,
            audioData: null,
            isUploading:false,
            error: undefined,
        })
    }

    //called when recording is completed(generally stop)
    done = async (data:any)=>{
        if(!data) {
            console.log("no audio data to upload to s3")
            return;
        }
        await fetch(data.url)
            .then(r => r.blob())
            .then(b=>this.props.api.putAudioFileToS3(this.props.surveyId, this.props.sessionId, b))
            .then(r => {
                this.setState({
                isUploading:false,
                audioS3Path:r,
                error: undefined
            })})
            .catch(error => {
                this.setState({
                    isUploading: false,
                    error,
                })
            })
    }

    // called when stop button is pressed on recorder
    stop = () => {
        this.setState({
            recordState: RecordState.STOP
        })
    }

    // reset the recording context to allow fresh recording
    reset = () => {
        this.setState({
            recordState: RecordState.STOP,
            audioData: null,
            isUploading:false,
            error: undefined,
        })
        this.props.onRecordingComplete("")
    }

    //helper method to convert a long pre-resigned s3 url to the corresponding s3 key
    processS3UrlToKey(signedUrl: string) {
        try{
            return signedUrl.split("?")[0].split(".com")[1].replace(/^\/+/, '');
        } catch (exception) {
            console.log("failed to parse the audio recording path")
            return "";
        }
    }

    onStop = (data: any) => {
        this.setState({
            audioData: data,
            isUploading:true
        })
        this.done(data).then(()=>{
                this.props.onRecordingComplete(this.state.audioS3Path)
            }
        )
    }

    // decide if a particular button should be disabled
    isDisabledState(disablingState:any) {
        return this.state.recordState == disablingState || this.state.isUploading;
    }

    render() {
        const { recordState } = this.state
        return (
            <Box display="flex" m="auto" alignItems="center" justifyContent="center">
                <Card style = {{ maxWidth: 350, alignItems: 'center'}} variant="outlined">
                    <CardContent style = {{alignItems: 'center'}}>
                        <AudioReactRecorder
                            state={recordState}
                            onStop={this.onStop}
                            backgroundColor='rgb(255,255,255)'
                            canvasHeight="100"
                        />
                    </CardContent>
                    <CardContent style = {{alignItems: 'center'}}>
                        {this.state.recordState == RecordState.START &&
                                <CountdownTimer totalSeconds={45}/>}
                        {this.state.audioData && !this.state.isUploading &&
                        (<Item elevation={0}><AudioPlayer rounded spacing={1}
                                                          src={this.state.audioData ? this.state.audioData.url : null}
                        /></Item>)}
                    </CardContent>
                    <CardContent style = {{alignItems: 'center'}}>
                        {!this.state.isUploading && <Grid container spacing={1}>
                            <Grid item xs={4} >
                                <Item elevation={0}>
                                    <Tooltip title="Start Recording">
                                        <Fab size="medium" disabled={this.isDisabledState(RecordState.START)} onClick={this.start}>{<Mic />} </Fab>
                                    </Tooltip>
                                </Item>
                            </Grid>
                            <Grid item xs={4} >
                                <Tooltip title="Stop Recording">
                                    <Item elevation={0}><Fab size="medium" disabled={this.isDisabledState(RecordState.STOP) || !this.state.recordState} onClick={this.stop}>{<Stop />} </Fab></Item>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={4} >
                                <Tooltip title="Delete & Restart">
                                    <Item elevation={0}><Fab  size="medium" disabled={this.isDisabledState(RecordState.START) || !this.state.audioData} onClick={this.reset}>{<Delete/>} </Fab></Item>
                                </Tooltip>
                            </Grid>
                        </Grid>}
                        {this.state.isUploading &&<div>
                            <Grid>Loading Audio....</Grid>
                            <Grid>
                                { <LinearProgress color="secondary" style={{
                                    height: 10}}/>}
                            </Grid></div> }
                    </CardContent>
                    {this.state.error && <CardContent>
                        {this.state.error}
                        </CardContent>}
                </Card>
            </Box>
        )
    }
}