import Client from "src/config/client";
import {getAmplifyConfig} from "src/config/amplifyConfigMap";
import { Amplify } from "aws-amplify";

export const createClient = (): Client => {
    const currentHost: string = window.location.host;
    let config = getAmplifyConfig(currentHost.toString())
    let client = new Client(
        config.identityPoolId,
        config.region,
        config.userPoolId,
        config.userPoolWebClientId,
        config.surveyTemplateBucketName,
        config.tempAudioBucketName)

    Amplify.configure({
        Auth: JSON.parse(JSON.stringify(client)),
        API: {
            endpoints: [client.getEndPoints(config.endpoint, config.name)]
        },
    });

    return client;
}
