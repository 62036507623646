export default class Client {

    // information for authentication
    identityPoolId: string
    region: string
    userPoolId: string
    userPoolWebClientId: string

    // information for s3 storage
    surveyTemplateBucketName: string;
    tempAudioBucketName: string;

    constructor(
        identityPoolId:string,
        region:string,
        userPoolId:string,
        userPoolWebClientId:string,
        surveyTemplateBucketName: string,
        tempAudioBucketName: string,
        ) {
        this.identityPoolId = identityPoolId;
        this.region = region;
        this.userPoolId = userPoolId;
        this.userPoolWebClientId = userPoolWebClientId;
        this.surveyTemplateBucketName = surveyTemplateBucketName;
        this.tempAudioBucketName = tempAudioBucketName;
    }

    getEndPoints(endpoint: string, name: string) {
        return {
            name: name,
            endpoint: endpoint,
            region: this.region,
        }
    }
}
